<template>
  <div>
    <b-card no-body class="payment-top mb-0">
      <b-card-body>
        <div class="pay-title">
          <feather-icon
            class="pay-icon"
            icon="CreditCardIcon"
          />
          <span class="text-pay">
            Pagamento
          </span>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body>
        <b-row>
          <b-col offset-xl="1" xl="10" cols="12">
            <form-wizard
              color="#2f79bb"
              :title="null"
              :subtitle="null"
              finish-button-text="Subscrever"
              back-button-text="Voltar"
              next-button-text="Salvar e continuar"
              class="steps-transparent"
              @on-complete="formSubmitted"
            >
              <tab-content
                title="Endereço de faturação"
                icon="feather icon-align-left"
              >
                <b-row class="address-detail">
                    <b-col xl="6">
                      <b-row>
                        <b-col xl="12">
                          <div class="address-section">
                            <feather-icon
                              class="address-icon"
                              icon="AlignLeftIcon"
                            />
                            <span class="address-text">
                              Endereço de faturação
                            </span>
                          </div>
                          <span class="address-description">Usado para calcular impostos e será exibido nos documentos de cobrança</span>
                          <b-form-group
                            class="label-text"
                            label="Nome da empresa"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>
                        <b-col xl="12">
                          <b-form-group
                            class="label-text"
                            label="Endereço"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>
                        <b-col xl="6">
                          <b-form-group
                            class="label-text"
                            label="País"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>
                        <b-col xl="6">
                          <b-form-group
                            class="label-text"
                            label="Estado / Província / Região"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>
                        <b-col xl="6">
                          <b-form-group
                            class="label-text"
                            label="Código Postal"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>
                        <b-col xl="6">
                          <b-form-group
                            class="label-text"
                            label="Contribuinte"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder=""
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col xl="6" class="sub-section">
                      <div class="sub-top">
                        <span class="sub-text">Subscrição</span>
                      </div>
                      <b-row>
                        <b-col cols="8">
                          <span class="sub-title">Dialog Premium</span>
                          <div class="desc-section">
                            <span class="desc-number">-16%</span>
                            <span class="desc-text">Desconto de pagamento anual</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="sub-values">
                            <span class="sub-value">432,00€</span>
                            <span class="sub-desc">-72,00€</span>
                            <hr>
                            <span class="sub-total">360,00€</span>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="resume-top">
                        <span class="resume-text">Resumo</span>
                      </div>
                      <span class="resume-description">Subscrição anual 1 equipamento sincronizado</span>
                      <b-row class="iva-section">
                        <b-col cols="8">
                          <div class="iva-calc">
                            <span class="iva-text">IVA</span>
                            <span class="iva-calculate">Calculado</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="iva-values">
                            <span class="iva-value">82,80€</span>
                          </div>
                        </b-col>
                        <hr>
                        <b-col cols="8">
                          <div class="iva-total">
                            <span class="total-text">Total</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="total-iva">
                            <span class="total-value">442,80€</span>
                          </div>
                        </b-col>
                        <b-col cols="8" class="poupa-section">
                          <div class="poupar">
                            <span class="poupar-text">Está a poupar</span>
                          </div>
                        </b-col>
                        <b-col cols="4" class="poupa-section">
                          <div class="total-poupar">
                            <span class="poupar-value">72,00€</span>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                </b-row>
              </tab-content>

              <tab-content
                title="Detalhe de pagamento"
                icon="feather icon-credit-card"
              >
                <b-row class="address-detail">
                    <b-col xl="6">
                      <b-row>
                        <b-col xl="12">
                          <div class="address-section">
                            <feather-icon
                              class="address-icon"
                              icon="CreditCardIcon"
                            />
                            <span class="address-text">
                              Detalhe do Pagamento
                            </span>
                          </div>
                          <span class="address-description">Adicionar um cartão de débito ou crédito</span>
                        </b-col>
                        <b-col xl="12">
                            <div class="select-pay">
                              <span class="select-text">Selecionar meio de pagamento</span>
                            </div>
                            <div class="select-payment">
                              <b-form-radio
                                v-model="Selected"
                                value="A"
                              >
                                <img src="https://fakeimg.pl/119x60/" alt="">
                              </b-form-radio>
                              <b-form-radio
                                v-model="Selected"
                                value="B"
                              >
                                <img src="https://fakeimg.pl/119x60/" alt="">
                              </b-form-radio>
                              <b-form-radio
                                v-model="Selected"
                                value="C"
                              >
                                <img src="https://fakeimg.pl/119x60/" alt="">
                              </b-form-radio>
                            </div>
                        </b-col>
                        <b-col cols="12" xl="12">
                          <b-form-group
                            class="label-text"
                            label="Número do cartão"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              type="number"
                              placeholder="5637 8172 1290"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" xl="6">
                          <b-form-group
                            class="label-text"
                            label="Nome no cartão"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder="John Doe"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" xl="3">
                          <b-form-group
                            class="label-text"
                            label="Data de validade"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder="MM/YY"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" xl="3">
                          <b-form-group
                            class="label-text"
                            label="Cód. de segurança"
                            label-for=""
                          >
                            <b-form-input
                              id=""
                              placeholder="CVV"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col xl="6" class="sub-section">
                      <div class="sub-top">
                        <span class="sub-text">Subscrição</span>
                      </div>
                      <b-row>
                        <b-col cols="8">
                          <span class="sub-title">Dialog Premium</span>
                          <div class="desc-section">
                            <span class="desc-number">-16%</span>
                            <span class="desc-text">Desconto de pagamento anual</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="sub-values">
                            <span class="sub-value">432,00€</span>
                            <span class="sub-desc">-72,00€</span>
                            <hr>
                            <span class="sub-total">360,00€</span>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="resume-top">
                        <span class="resume-text">Resumo</span>
                      </div>
                      <span class="resume-description">Subscrição anual 1 equipamento sincronizado</span>
                      <b-row class="iva-section">
                        <b-col cols="8">
                          <div class="iva-calc">
                            <span class="iva-text">IVA</span>
                            <span class="iva-calculate">Calculado</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="iva-values">
                            <span class="iva-value">82,80€</span>
                          </div>
                        </b-col>
                        <hr>
                        <b-col cols="8">
                          <div class="iva-total">
                            <span class="total-text">Total</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="total-iva">
                            <span class="total-value">442,80€</span>
                          </div>
                        </b-col>
                        <b-col cols="8" class="poupa-section">
                          <div class="poupar">
                            <span class="poupar-text">Está a poupar</span>
                          </div>
                        </b-col>
                        <b-col cols="4" class="poupa-section">
                          <div class="total-poupar">
                            <span class="poupar-value">72,00€</span>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                </b-row>
              </tab-content>

              <tab-content
                title="Resumo do pagamento"
                icon="feather icon-file-text"
              >
                <b-row class="address-detail">
                    <b-col xl="6">
                      <b-row>
                        <b-col xl="12">
                          <div class="address-section">
                            <feather-icon
                              class="address-icon"
                              icon="FileTextIcon"
                            />
                            <span class="address-text">
                              Resumo do Pagamento
                            </span>
                          </div>
                        </b-col>
                        <b-col xl="6">
                          <div class="details">
                            <span class="title-text">Endereço</span>
                            <span class="description-text">
                              Z.I. Norte Rua do Portinho<br/>
                              3750-175<br/>
                              Águeda<br/>
                              Portugal
                            </span>
                          </div>
                        </b-col>
                        <b-col xl="6">
                          <div class="details">
                            <span class="title-text">Contribuinte</span>
                            <span class="description-text">000000000</span>
                          </div>
                        </b-col>
                        <b-col xl="6">
                          <div class="details">
                            <span class="title-text">Meio de pagamento</span>
                            <span class="description-text">
                              0000 0000 0000 0000 <br/>
                              MM/YY <br/>
                              Diogo Costa <br/>
                              000
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col xl="6" class="sub-section">
                      <div class="sub-top">
                        <span class="sub-text">Subscrição</span>
                      </div>
                      <b-row>
                        <b-col cols="8">
                          <span class="sub-title">Dialog Premium</span>
                          <div class="desc-section">
                            <span class="desc-number">-16%</span>
                            <span class="desc-text">Desconto de pagamento anual</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="sub-values">
                            <span class="sub-value">432,00€</span>
                            <span class="sub-desc">-72,00€</span>
                            <hr>
                            <span class="sub-total">360,00€</span>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="resume-top">
                        <span class="resume-text">Resumo</span>
                      </div>
                      <span class="resume-description">Subscrição anual 1 equipamento sincronizado</span>
                      <b-row class="iva-section">
                        <b-col cols="8">
                          <div class="iva-calc">
                            <span class="iva-text">IVA</span>
                            <span class="iva-calculate">Calculado</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="iva-values">
                            <span class="iva-value">82,80€</span>
                          </div>
                        </b-col>
                        <hr>
                        <b-col cols="8">
                          <div class="iva-total">
                            <span class="total-text">Total</span>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div class="total-iva">
                            <span class="total-value">442,80€</span>
                          </div>
                        </b-col>
                        <b-col cols="8" class="poupa-section">
                          <div class="poupar">
                            <span class="poupar-text">Está a poupar</span>
                          </div>
                        </b-col>
                        <b-col cols="4" class="poupa-section">
                          <div class="total-poupar">
                            <span class="poupar-value">72,00€</span>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                </b-row>
              </tab-content>
            </form-wizard>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BListGroup, BListGroupItem, BCardText, BButton, BRow, BCol, BFormGroup, 
  BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BProgress, BProgressBar, BFormRadio
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { codeCustom } from './code'


export default {
  components: {
    FormWizard,
    TabContent,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BListGroupItem,
    BProgress,
    BProgressBar,
    BFormRadio,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Selected: 'A',
      codeCustom,
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>